import { reconmmandNewSong } from '@/api';
import SingleSong from '@/components/SingleSong';
export default {
  data() {
    return {
      topSongList: []
    };
  },
  async created() {
    const res = await reconmmandNewSong({
      limit: 15
    });
    //console.log(res)
    this.topSongList = res.data.result;
  },
  components: {
    SingleSong
  }
};