import request from '@/utils/request'
export const recommandMusic = params => request({
        url: '/personalized',
        //传入参数，限制数量
        params
    })
    //首页推荐榜单
export const reconmmandNewSong = params => request({
        url: '/personalized/newsong',
        params
    })
    //搜索热门歌曲
export const hotMusic = params => request({
        url: '/search/hot',
        params
    })
    //搜索页面
export const searchResult = params => request({
    url: '/search',
    params
})

//获取歌曲播放信息
export const getSongById = (id) => request({
    url: `/song/detail?ids=${id}`,
    method: "GET"
})

// 播放页 - 获取歌词
export const getLyricById = (id) => request({
    url: `/lyric?id=${id}`,
    method: "GET"
})