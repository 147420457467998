import "core-js/modules/es.array.push.js";
export default {
  props: {
    name: String,
    author: String,
    id: Number
  },
  methods: {
    musicPlay() {
      this.$router.push({
        path: '/play',
        query: {
          id: this.id
        }
      });
    }
  }
};