import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", null, [_createVNode(_component_van_nav_bar, {
    "left-text": "仿制，侵删。没会员，有的歌曲不能完整播放。",
    fixed: ""
  })]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)]), _createElementVNode("div", null, [_createVNode(_component_van_tabbar, {
    modelValue: $data.active,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.active = $event),
    router: "",
    "active-color": "#ee0a24",
    onSubActive: $options.changeState
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/layout/home",
      icon: "home-o"
    }, {
      default: _withCtx(() => [_createTextVNode("推荐")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/layout/hot",
      icon: "fire-o"
    }, {
      default: _withCtx(() => [_createTextVNode("热歌")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/layout/search",
      icon: "search"
    }, {
      default: _withCtx(() => [_createTextVNode("搜索")]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onSubActive"])])], 64);
}