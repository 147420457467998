import { recommandMusic, reconmmandNewSong } from '@/api';
import SingleSong from '@/components/SingleSong';
export default {
  data() {
    return {
      list: [],
      songList: []
    };
  },
  async created() {
    const res = await recommandMusic({
      limit: 6
    });
    // console.log(res)
    this.list = res.data.result;
    const res2 = await reconmmandNewSong({
      limit: 40
    });
    this.songList = res2.data.result;
  },
  components: {
    SingleSong
  }
};