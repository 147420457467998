import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c2a0424"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "play"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "song-wrapper"
};
const _hoisted_4 = {
  class: "song-img"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "song-start"
};
const _hoisted_7 = {
  class: "song-msg"
};
const _hoisted_8 = {
  class: "m-song-h2"
};
const _hoisted_9 = {
  class: "m-song-sname"
};
const _hoisted_10 = {
  class: "lrcContent"
};
const _hoisted_11 = {
  class: "lrc"
};
const _hoisted_12 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "song-bg",
    style: _normalizeStyle(`background-image: url(${$data.songInfo && $data.songInfo.al && $data.songInfo.al.picUrl}?imageView&thumbnail=360y360&quality=75&tostatic=0);`)
  }, null, 4), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_icon, {
    name: "arrow-left",
    size: "20",
    class: "left-incon",
    onClick: $options.backAndPause
  }, null, 8, ["onClick"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "song-turn ani",
    style: _normalizeStyle(`animation-play-state:${$data.playState ? 'running' : 'paused'}`)
  }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
    style: {
      "width": "100%"
    },
    src: `${$data.songInfo && $data.songInfo.al && $data.songInfo.al.picUrl}?imageView&thumbnail=360y360&quality=75&tostatic=0`,
    alt: ""
  }, null, 8, _hoisted_5)])], 4), _createElementVNode("div", {
    class: "start-box",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.audioStart && $options.audioStart(...args))
  }, [_withDirectives(_createElementVNode("span", _hoisted_6, null, 512), [[_vShow, !$data.playState]])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("h2", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString($data.songInfo.name) + "-" + _toDisplayString($data.songInfo && $data.songInfo.ar && $data.songInfo.ar[0].name), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("p", _hoisted_11, _toDisplayString($data.curLyric), 1)])]), _createElementVNode("div", {
    class: "needle",
    style: _normalizeStyle(`transform: rotate(${$options.needleDeg});`)
  }, null, 4)]), _createElementVNode("audio", {
    ref: "audio",
    preload: "true",
    src: `https://music.163.com/song/media/outer/url?id=${$data.id}.mp3`
  }, null, 8, _hoisted_12)]);
}