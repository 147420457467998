import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fb6f50a2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "firstImg"
};
const _hoisted_2 = {
  class: "secImg"
};
const _hoisted_3 = {
  class: "secWrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  const _component_SingleSong = _resolveComponent("SingleSong");
  const _component_lazy_component = _resolveComponent("lazy-component");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_image, {
    width: "10rem",
    height: "5rem",
    fit: "contain",
    src: require('../../assets/img/hot_music_bg_3x.jpg')
  }, null, 8, ["src"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_image, {
    width: "5rem",
    height: "3rem",
    src: require('../../assets/img/index_icon_2x.png')
  }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_lazy_component, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.topSongList, obj => {
      return _openBlock(), _createBlock(_component_SingleSong, {
        key: obj.id,
        name: obj.name,
        id: obj.id,
        author: obj.song.artists[0].name
      }, null, 8, ["name", "id", "author"]);
    }), 128))]),
    _: 1
  })])]);
}