export default {
  data() {
    return {
      active: 0
    };
  },
  methods: {
    changeState() {
      console.log(this.$route.path);
      if (this.$route.path === '/layout/home') {
        this.active = 0;
      }
      if (this.$route.path === '/layout/hot') {
        this.active = 1;
      }
      if (this.$route.path === '/layout/search') {
        this.active = 2;
      }
    }
  },
  mounted() {
    this.changeState();
  }
};