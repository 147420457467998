import { hotMusic, searchResult } from '@/api';
import SingleSong from '@/components/SingleSong';
export default {
  data() {
    return {
      value: "",
      hotList: [],
      searchResultList: [],
      timer: null
    };
  },
  async created() {
    const res = await hotMusic();
    this.hotList = res.data.result.hots;
  },
  methods: {
    // 搜索结果
    async getList() {
      return await searchResult({
        keywords: this.value,
        limit: 30
      });
    },
    // 热搜词,点击后直接刷列表
    async hotSearch(val) {
      this.value = val;
      const res = await this.getList();
      console.log(res);
      this.searchResultList = res.data.result.songs;
    }
  },
  watch: {
    async value(newVal, oldVal) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (this.value.length === 0) {
          this.searchResultList = [];
          return;
        }
        const res = await this.getList();
        this.searchResultList = res.data.result.songs;
      }, 500);
    }
  },
  components: {
    SingleSong
  }
};