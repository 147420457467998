import { createApp } from 'vue'
import App from './App.vue'
import "@/mobile/flexible"
import "@/styles/reset.css"
import router from '@/router'
import { Tabbar, TabbarItem, Col, Row, Image as VanImage, Cell, CellGroup, Icon, Lazyload, NavBar, Search } from 'vant'
import { recommandMusic } from '@/api/index'

const app = createApp(App)
app.use(router)
app.use(NavBar);
app.use(Tabbar)
app.use(TabbarItem)
app.use(Col);
app.use(Row);
app.use(VanImage);
app.use(Cell);
app.use(CellGroup);
app.use(Icon);
app.use(Lazyload, {
    lazyComponent: true,
});
app.use(Search)
app.mount('#app')