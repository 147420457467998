import Vue from 'vue'
import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import Layout from '@/views/Layout'
import Play from '@/views/Play'
import Search from '@/views/Search'
import Hot from '@/views/Hot'

const routes = [{
        path: '/',
        redirect: '/layout'
    },
    {
        path: '/layout',
        component: Layout,
        redirect: '/layout/home',
        children: [{
                path: 'home',
                component: Home
            },
            {
                path: 'search',
                component: Search
            },
            {
                path: 'hot',
                component: Hot
            }
        ]
    },
    {
        path: '/play',
        component: Play
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/layout'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router