import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_cell = _resolveComponent("van-cell");
  return _openBlock(), _createBlock(_component_van_cell, {
    center: "",
    key: $props.id,
    title: $props.name,
    label: $props.author + ' - ' + $props.name
  }, {
    "right-icon": _withCtx(() => [_createVNode(_component_van_icon, {
      name: "play-circle-o",
      size: "0.6rem",
      onClick: $options.musicPlay
    }, null, 8, ["onClick"])]),
    _: 1
  }, 8, ["title", "label"]);
}